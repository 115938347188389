<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Codigos Sicom</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Codigos Sicom</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_CodigoSiscom_export"
                        v-if="$store.getters.can('admin.codigoSiscom.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-CodigoSiscom"
                        @click="abrirModal('Crear', null)"
                        v-if="$store.getters.can('admin.codigoSiscom.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Sitio Origen
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio_origen"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Sitio Destino
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio_destino"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Producto Liquido
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.producto_liquido_id"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Codigo Sicom
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.cod_sicom"
                          @keyup="getIndex()"
                        />
                      </th>

                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="codigos in codigos_siscom.data"
                      :key="codigos.id"
                    >
                      <td>{{ codigos.sitio_origen.nombre }}</td>
                      <td>{{ codigos.sitio_destino.nombre }}</td>
                      <td>{{ codigos.producto_liquido.nombre }}</td>
                      <td>{{ codigos.cod_sicom }}</td>

                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-CodigoSiscom"
                            @click="abrirModal('Editar', codigos.id)"
                            v-if="$store.getters.can('admin.codigoSiscom.edit')"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            v-if="
                              $store.getters.can('admin.codigoSiscom.delete')
                            "
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(codigos.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="codigos_siscom.total">
                  <p>
                    Mostrando del <b>{{ codigos_siscom.from }}</b> al
                    <b>{{ codigos_siscom.to }}</b> de un total:
                    <b>{{ codigos_siscom.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="codigos_siscom"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                >
                </pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-CodigoSiscom">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              v-if="$store.getters.can('admin.codigoSiscom.show')"
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="sitio_origen">Sitio Origen</label>
              <select
                id="sitio_origen"
                class="form-control form-control-sm"
                v-model="objcodigos_siscom.sitio_origen"
                :class="
                  $v.objcodigos_siscom.sitio_origen.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="sitios in listasForms.sitios"
                  :key="sitios.id"
                  :value="sitios.id"
                >
                  {{ sitios.nombre }}
                </option>
              </select>
              <div
                class="error"
                v-if="!$v.objcodigos_siscom.sitio_origen.required"
              >
                Seleccione un sitio de origen
              </div>
            </div>

            <div class="form-group">
              <label for="sitio_destino">Sitio Destino</label>
              <select
                id="sitio_destino"
                class="form-control form-control-sm"
                v-model="objcodigos_siscom.sitio_destino"
                :class="
                  $v.objcodigos_siscom.sitio_destino.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="sitios in listasForms.sitios"
                  :key="sitios.id"
                  :value="sitios.id"
                >
                  {{ sitios.nombre }}
                </option>
              </select>
              <div
                class="error"
                v-if="!$v.objcodigos_siscom.sitio_destino.required"
              >
                Seleccione un sitio de destino
              </div>
            </div>

            <div class="form-group">
              <label for="producto_liquido_id">Producto liquido</label>
              <select
                id="producto_liquido_id"
                class="form-control form-control-sm"
                v-model="objcodigos_siscom.producto_liquido_id"
                :class="
                  $v.objcodigos_siscom.producto_liquido_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="productosLiquidos in listasForms.productosLiquidos"
                  :key="productosLiquidos.id"
                  :value="productosLiquidos.id"
                >
                  {{ productosLiquidos.nombre }}
                </option>
              </select>
              <div
                class="error"
                v-if="!$v.objcodigos_siscom.producto_liquido_id.required"
              >
                Seleccione un producto liquido
              </div>
            </div>

            <div class="form-group">
              <label for="cod_sicom">Código Sicom</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="cod_sicom"
                placeholder="Código Siscom"
                v-model="objcodigos_siscom.cod_sicom"
                :class="
                  $v.objcodigos_siscom.cod_sicom.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
                @blur="valcod()"
              />
              <div
                class="error"
                v-if="!$v.objcodigos_siscom.cod_sicom.required"
              >
                Ingrese un código Sicom
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.objcodigos_siscom.$invalid"
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- <CodigoSiscomExport ref="CodigoSiscomExport" /> -->
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
//import CodigoSiscomExport from "./CodigoSiscomExport";

export default {
  name: "CodigoSiscomIndex",
  components: {
    pagination,
    Loading,
    //	CiudadExport,
  },
  data() {
    return {
      Eliminar: false,
      metodo: "",
      filtros: {
        sitio_origen: null,
        Sitio_destino: null,
        cod_sicom: null,
        producto_liquido_id: null,
      },
      codigos_siscom: {},
      objcodigos_siscom: {},

      listasForms: {
        sitios: [],
        productosLiquidos: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      cargando: false,
      addressInput: "",
      page: "",
    };
  },
  validations: {
    objcodigos_siscom: {
      sitio_origen: {
        required,
      },
      sitio_destino: {
        required,
      },
      producto_liquido_id: {
        required,
      },
      cod_sicom: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.page = page;
      axios
        .get("/api/admin/codigoSiscom?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.codigos_siscom = response.data;
        });
    },
    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },
    getProductosLiquidos() {
      axios.get("/api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productosLiquidos = response.data;
      });
    },

    abrirModal(accion, idcodigoSiscom) {
      this.modal.accion = accion;
      this.modal.title = accion + " Codigo Siscom";
      if (accion == "Editar") {
        this.show(idcodigoSiscom);
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
        this.objcodigos_siscom = {};
      }
    },

    save() {
      if (!this.$v.objcodigos_siscom.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/admin/codigoSiscom",
          data: this.objcodigos_siscom,
        })
          .then((response) => {
            this.$refs.closeModal.click();
            this.objcodigos_siscgetom = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    show(idcodigoSiscom) {
      axios
        .get("/api/admin/codigoSiscom/show/" + idcodigoSiscom)
        .then((response) => {
          this.objcodigos_siscom = response.data;
        });
    },

    valcod() {
      let params = {
        codigos_siscom: this.objcodigos_siscom.cod_sicom,
      };
      axios
        .get("/api/admin/codigoSiscom/lista", {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.objcodigos_siscom.cod_sicom = null;
            this.$swal({
              icon: "error",
              title: "El código digitado ya se encuentra creado...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    destroy(idcodigoSiscom) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/codigoSiscom/" + idcodigoSiscom)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getSitios();
    this.getProductosLiquidos();
  },
};
</script>
